define('Kemana_NewsletterCoupon/js/newsletter-popup',[
    'jquery',
    'Magento_Ui/js/modal/modal',
    'mage/mage',
    'jquery/ui',
    'js-cookie/cookie-wrapper'
], function ($, modal) {
    'use strict';

    $.widget('kemana.newsletterPopup', {

        /**
         *
         * @private
         */
        _create: function () {
            var self = this,
                popup_newsletter_options = {
                    type: 'popup',
                    responsive: true,
                    innerScroll: true,
                    title: '',
                    buttons: false,
                    modalClass : 'popup-newsletter'
            };

            var news_cook = $.cookie('newsletter');
            var currentPathname = window.location.pathname,
                avoid = "/";
            currentPathname = currentPathname.split(avoid).join('');
            if (!news_cook && currentPathname != 'newsletter-marketing') {
                $.cookie('newsletter', "exists");
                modal(popup_newsletter_options, this.element);
                setTimeout(function () {
                    self._setStyleCss();
                    self.element.modal('openModal');
                }, 3000);
            } else {
                setTimeout(function () {
                    self.customDobGenderPopup();
                }, self.options.delayTime);
            }
            /*
                Reset message on click close event
             */
            $('#popup-newsletter').on('modalclosed', function () {
                self.element.find('#newsletter-validate-detail-pop')[0].reset();
                self.element.find('#newsletter-error').empty();
                self.element.find('.message-content .message div').empty();
                self.element.find('.message-content .message').removeClass('message-error error');
                self.element.find('.message-content .message').removeClass('message-success success');
                self.customDobGenderPopup();
            });
            /*
                Start PMC-219
             */
            $("#homeNewslaterPopup, #homeNewslaterPopup_mobile").on('click',function () {
                //Show again modal contain
                self.element.find('.image-container, .popup-title, .intro, .content form').show();
                modal(popup_newsletter_options, $('#popup-newsletter'));
                setTimeout(function () {
                    self._setStyleCss();
                    self.element.modal('openModal');
                }, 2000);
            });
            /*
                End PMC-219
             */
            this.element.find('form').submit(function () {
                if ($(this).validation('isValid')) {
                    $.ajax({
                        url: $(this).attr('action'),
                        cache: true,
                        data: $(this).serialize(),
                        dataType: 'json',
                        type: 'POST',
                        showLoader: true
                    }).done(function (data) {
                        self.element.find('.messages .message div').html(data.message);
                        self.element.find('.message-content .message div').html(data.message);
                        self.element.find('.message-content .message').removeClass('message-error error');
                        self.element.find('.message-content .message').removeClass('message-success success');
                        if (data.error) {
                            self.element.find('.message-content .message').addClass('message-error error');
                        } else {
                            // Hides modal contain only successfully subscribe
                            self.element.find('.image-container, .popup-title, .intro, .content form').hide();
                            // Display success message only successfully subscribe
                            self.element.find('.message-content .message').addClass('message-success success');
                            setTimeout(function () {
                                self.element.modal('closeModal');
                            }, 3500);
                        }
                        self.element.find('.message-content').show();
                    });
                }
                return false;
            });

            this._resetStyleCss();
            this._setLineheight();
        },

        /**
         * Set width of the popup
         * @private
         */
        _setStyleCss: function (width) {

            width = width || 460;

            if (window.innerWidth > 786) {
                this.element.parent().parent('.modal-inner-wrap').css({'max-width': width+'px','top': '45%','transform': 'translateY(-50%)'});
            }
        },

        /**
         * Reset width of the popup
         * @private
         */
        _resetStyleCss: function () {
            var self = this;
            $(window).resize(function () {
                if (window.innerWidth <= 786) {
                    self.element.parent().parent('.modal-inner-wrap').css({'max-width': 'initial'});
                } else {
                    self._setStyleCss(self.options.innerWidth);
                }
            });
        },
        _setLineheight:function () {
            var textLength = $('.popup-title h1').text().length;
            if (window.innerWidth <= 786) {
                if (textLength <= 7 ) {
                    $('.popup-title h1').css('font-size', '60px')
                } else if (textLength <= 9) {
                    $('.popup-title h1').css('font-size', '50px')
                } else if (textLength <= 14) {
                    $('.popup-title h1').css('font-size', '30px')
                } else if (textLength <= 21) {
                    $('.popup-title h1').css('font-size', '20px')
                }
            } else {
                if (textLength <= 7 ) {
                    $('.popup-title h1').css({'font-size':'80px','letter-spacing': '-6px'})
                } else if (textLength <= 9) {
                    $('.popup-title h1').css({'font-size':'50px','letter-spacing': '-4px'})
                } else if (textLength <= 14) {
                    $('.popup-title h1').css({'font-size':'30px'})
                } else if (textLength <= 21) {
                    $('.popup-title h1').css({'font-size':'20px'})
                }
            }
        },

        /**
         * Customer DOB and Gender Popup Code
         */
        customDobGenderPopup: function () {
            var self = this;
            if (!$.cookie('customer_dob_gender')) {
                if (self.options.isCustomerLoggedIn) {
                    if (self.options.dob != null || self.options.gender > '0') {
                        var options = {
                            type: 'popup',
                            responsive: true,
                            innerScroll: true,
                            modalClass: 'z_index dob_popup',
                            buttons: [{
                                text: $.mage.__('Close'),
                                class: '',
                                click: function () {
                                    this.closeModal();
                                }
                            }]
                        };
                        //var popup = modal(options, $('#custom-popup-modal'));
                        $('#custom-popup-modal').modal(options).modal('openModal');
                        $.cookie('customer_dob_gender', '1', {path: '/', domain: ''});
                    }
                }
            }
        }
    });

    return $.kemana.newsletterPopup;
});

