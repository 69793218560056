/*
Author  : Born Group.
main jQuery widget of Fpmini
*/

define('Born_PerformanceFixes/js/cms_page',[
    'jquery',
    'jquery/ui',
    'mage/translate',
    'Born_WeltPixelGtm/js/born_gtm',
    'owl_carousel',
    'slick',
    'fancybox'
], function ($, ui, _responsive, born_gtm) {
    'use strict';

    $.widget('planetsports.initCmsPage', {

        _create: function () {
            this.initCmsPage();
            this.initHomePage();
        },
        initCmsPage: function() {
            if ($('body.cms-page-view').length) {
                //link-anchor running page
                $("#explore").click(function() {
                    $('html,body').animate({
                            scrollTop: $(".subcategory-items").offset().top},
                        1500);
                });
                $("#lat-articles").click(function() {
                    $('html,body').animate({
                            scrollTop: $(".series.articles").offset().top},
                        1500);
                });
                $("#vid-gallery").click(function() {
                    $('html,body').animate({
                            scrollTop: $(".playlist.video, .series.list-video").offset().top},
                        1800);
                });
                $("#up-events").click(function() {
                    $('html,body').animate({
                            scrollTop: $(".slider-bottom-landing").offset().top},
                        1800);
                });

                //banner links scroll
                $('.links-scroll').click(function() {
                    $(".left-linkcatalog ul").animate({ scrollTop: $('.left-linkcatalog ul').prop("scrollHeight")}, 2000);
                });
            }
        },

        initHomePage: function() {
            $(document).ready(function(){
                var featured = $('.custom-products-carousel .widget-product-grid');
                featured.owlCarousel({
                    items: 5,
                    slideSpeed: 800,
                    nav: true,
                    dots: false,
                    autoplay: false,
                    loop: true,
                    smartSpeed:1500,
                    responsive : {
                        // breakpoint from 0 up
                        0 : {
                            items : 2,
                            nav:true,
                            dots:false
                        },
                        // breakpoint from 480 up
                        480 : {
                            items : 2,
                            nav:true,
                            dots:false
                        },
                        // breakpoint from 768 up
                        768 : {
                            items : 4,
                            nav:true,
                            dots:false
                        },
                        // breakpoint from 768 up
                        940 : {
                            items : 5,
                            nav:true,
                            dots:false
                        }
                    }
                });
            });
            if ($('body.cms-index-index').length) {
                var interval = setInterval(function () {
                    if (!$(".insta-planetsports .fs-mobile .fs-entry-container .fs-timeline-entry").hasClass("fs-loading")) {
                        $(".insta-planetsports .fs-mobile").addClass("changed");
                        clearInterval(interval);
                    }
                }, 1000);

                $( window ).resize(function() {
                    if (window.innerWidth < 786) {
                        if (!$(".insta-planetsports .fs-mobile .fs-entry-container .fs-timeline-entry").hasClass("fs-loading")) {
                            $(".insta-planetsports .fs-mobile").addClass("changed");
                        }
                    } else {
                        $(".insta-planetsports .fs-mobile").addClass("changed");
                    }
                });
            }
        },
    });

    return $.planetsports.initCmsPage;

});

